import { ILanguage } from '@/interfaces/common.interface';
import { ICockpitSettingsResponse, IRouteConfig, IRouteConfigExtended, IReportsconfig, IMenuSettings } from '@/interfaces/settings.interface';
import { ISignalRSettings } from '@/interfaces/web-api/signalR.interface';
import { IUserRole, UserRoleName } from '@/interfaces/web-api/user.interface';
import { $i18N } from '@/services/i18n.service';
import { gridModule } from '@/store/modules/grid.module';
import { i18NModule } from '@/store/modules/i18n.module';
import { kpiModule } from '@/store/modules/kpi.module';
import { filterColumnSettings } from '@/store/modules/filterColumnSetting.module';
import { settingsModule } from '@/store/modules/settings.module';
import { signalRModule } from '@/store/modules/signalR.module';
import { IGridState } from '@/store/states/grid-state.interface';
import { $customViews } from './custom-views.service';
import { $environment } from './environment.service';
import { getCaseInsensitive } from '@/utilities/get-case-insensitive.function';
import { $webApi } from './web-api.service';
import { $permission } from './permission.service';
import { $user } from './user.service';
import { $system } from './system.service';
import { sortModule } from '@/store/modules/sort.module';
import { groupModule } from '@/store/modules/group.module';

class SettingsService {
    private settingsPromise?: Promise<any>;
    private promiseDone: boolean = false;

    get isReady() {
        return !this.settingsPromise && this.promiseDone;
    }

    get currentLanguage(): string {
        const { langCode } = i18NModule;

        return langCode;
    }

    get defaultLanguage(): string {
        return $user.defaultLanguage || 'en-GB';
    }

    get businessUnitName(): string {
        return settingsModule.getBusinessUnitName || '';
    }

    get picture(): string | null {
        return settingsModule.getPicture;
    }

    async whenReady(): Promise<void> {
        if (this.isReady) { return; }

        return this.settingsPromise;
    }

    async getRequiredSettings() {
        this.settingsPromise = new Promise(async (res, rej) => {
            const cockpitPromise = this.useCockpitSettings();

            const signalRPromise = this.getSignalRSettings();

            const translationsPromise = $i18N.fetchLang($settingsService.defaultLanguage);
            
            await Promise.all([cockpitPromise, signalRPromise, translationsPromise]);

            this.clearPromise();
            return res(null);
        });

        return this.settingsPromise;
    }

    async getSignalRSettings(forceReload = false): Promise<ISignalRSettings> {
        let settings: ISignalRSettings = signalRModule.getSignalRSettings;

        if (!forceReload && settings && settings.signalREndpoint) {
            return settings;
        }

        const backendSettings = await $webApi.GET.User.SignalRSettings();

        if (!backendSettings) {
            return settings;
        }

        const result = backendSettings.signalR;

        const isAzureSignalR = result.isAzureSignalR === 'True';

        let token: string = '';

        if (isAzureSignalR) {
            token = await $webApi.GET.Authentication.SignalR();
        }

        settings = {
            isAzureSignalR,
            signalREndpoint: result.signalREndpoint,
            token
        };

        signalRModule.setSignalRSettings(settings);

        return settings;
    }

    async useCockpitSettings(): Promise<void> {
        // GET settings
        const backendSettings = await $webApi.GET.User.CockpitSettingsAll();

        // USE settings
        const { userSettings, permissions } = backendSettings;
        if (userSettings) {
            const { displayname, heartBeat, rowsLimit, timeStampSettings, numberFormatSettings, companyInfo } = userSettings;
            if (displayname) {
                settingsModule.setDisplayName(displayname);
            } else {
                this.setDisplayName(settingsModule.getBusinessUnitName);
            }
            

            if (heartBeat) {
                $system.updateHeartRate(Number(heartBeat));
            }
            
            if (timeStampSettings) {
                settingsModule.setTimeStampSettings(timeStampSettings);
            }

            if (companyInfo) {
                settingsModule.setcompanyInfoSettings(companyInfo);
            }
            
            if (numberFormatSettings) {
                settingsModule.setNumberFormatSettings(numberFormatSettings);
            }
            if (rowsLimit) {
                settingsModule.setRowsLimit(rowsLimit);
            }
        }
        $permission.load(permissions);

        await Promise.all([
            this.setRoutes(backendSettings),
            this.setGridSettings(backendSettings),
            this.setKpiSettings(backendSettings),
            this.setFilterColumnSetting(backendSettings),
            this.setSortColumnSettings(backendSettings),
            this.setGroupColumnSettings(backendSettings)
        ]);

        return;
    }

    private setDisplayName(value: string | null) {
        if (!value) { return; }

        $webApi.POST.Seeding.UserSettings([{ key: 'displayname', value }]);
    }

    private async setRoutes(result: ICockpitSettingsResponse) {
        let routes: IRouteConfig[] = [];

        if (result) {
            routes = this.extractRoutes(result);
        }

        if (!routes || routes.length === 0) {
            routes = $environment.routes;
        }

        return settingsModule.setRoutes(routes);
    }

    private async setGridSettings(result: ICockpitSettingsResponse) {
        if (!result) { return; }

        try {
            const grid = getCaseInsensitive(result, 'GridSettings');
            
            return gridModule.setSettings(grid);
        } catch (error) {
            return;
        }
    }

    private async setKpiSettings(result: ICockpitSettingsResponse) {
        if (!result) { return; }

        const kpi = getCaseInsensitive(result, 'KpiSettings');
        if (!kpi) { return; }

        let homeKpis = getCaseInsensitive(kpi, 'Home');
        try {
            homeKpis = JSON.parse(homeKpis);

            return kpiModule.setKpis(homeKpis);
        } catch (error) {
            return;
        }
    }
    private async setFilterColumnSetting(result: ICockpitSettingsResponse) {
        if (!result) { return; }

        try {
            const grid = getCaseInsensitive(result, 'filterColumnSettings');
            return filterColumnSettings.setFilterColumnSettings(grid);
        } catch (error) {
            return;
        }
    }

    async getAvailableRoutes(): Promise<IRouteConfig[]> {
        let routes: IRouteConfig[] = settingsModule.routes;

        if (routes && routes.length > 0) {
            return Promise.resolve(routes.filter(route => route.isAllowed));
        }

        const backendSettings = await $webApi.GET.User.MenuSettings();

        if (!backendSettings) { return []; }

        routes = this.extractRoutes(backendSettings);

        if (!routes || routes.length === 0) {
            routes = $environment.routes;
        }

        settingsModule.setRoutes(routes);

        return routes.filter(route => route.isAllowed);
    }
    
    async getRouteConfigs(): Promise<any>  {
        return await $webApi.GET.User.RoutesSettings();
    }

    async getAvailableLanguages(): Promise<ILanguage[]> {
        let langs: ILanguage[] = settingsModule.availableLanguage;

        if (langs.length === 0) {
            const languages = await $webApi.GET.Localization.Languages();

            langs = languages.map(code => {
                return {
                    name: code,
                    code
                };
            });

            settingsModule.setAvailableLanguages(langs);
        }

        return langs;
    }

    async getAvailableRoles(): Promise<IUserRole[]> {
        let roles: IUserRole[] = settingsModule.availableUserRoles;

        if (roles.length === 0) {
            roles = await $webApi.GET.UserRole.Header();

            settingsModule.setAvailableUserRoles(roles);
        }

        return roles;
    }

    async getGridStateFor(apiViewName: string, customViewName: string): Promise<any> {
       const data = $customViews.CustomViewName;
        if (customViewName !== data) {
            const cvSettings = await $customViews.getCustomView(apiViewName, customViewName);

            if (cvSettings && cvSettings.gridSettings) {
                return JSON.parse(cvSettings.gridSettings);
            }
        }

        const state = this.getGridState(apiViewName);

        return state;
    }

    async getCustomView(apiViewName: string, customName: string) {
        const cvResult = await $customViews.getCustomView(apiViewName, customName);

        return cvResult;
    }

    async getCustomViewsFor(apiViewName: string) {
        const customViewNames = await $customViews.getCustomViewsFor(apiViewName);
       
        return customViewNames;
    }

    async deleteCustomView(apiViewName: string, customName: string) {
        const cvResult = await $customViews.deleteCustomView(apiViewName, customName);

        return cvResult;
    }

    getGridState(apiViewName: string): IGridState | null {
        if (gridModule.hasOwnProperty(apiViewName)) {
            return gridModule[apiViewName] as IGridState;
        }

        return null;
    }

    saveGridStateFor(type: 'user' | 'customView' | 'BUM' | 'ALL' , apiViewName: string, state: any, customName?: string) {
        gridModule.setState({ apiViewName, dxState: state });

        const stateStr = typeof state === 'string' ? state : JSON.stringify(state);
        switch (type) {
            case 'ALL':
                return this.saveBaseGridState(apiViewName, stateStr);
            case 'BUM':
                return this.saveBUMGridState(apiViewName, stateStr);
            case 'customView':
                return this.saveCustomView(apiViewName, stateStr, customName || '');
            case 'user':
                return this.saveUserGridState(apiViewName, stateStr);
        }
    }

    saveSettingsFor(type: 'user' | 'BUM' | 'ALL', owner: string, value: string) {
        if (owner === 'KpiSettings') {
            switch (type) {
                case 'BUM': return this.saveBUMKpiSettings(value);
                case 'ALL': return this.saveBaseKpiSettings(value);
                case 'user': return this.saveUserKpiSettings(value);
            }
        }
    }

    hasPermissionAs(roleName: UserRoleName) {
        return $user.roles.some(role => role.userRoleName === roleName);
    }

    hasAdminPermission() {
        return this.hasPermissionAs('AdminRole');
    }

    hasGlobalAdminPermission() {
        return this.hasPermissionAs('GlobalAdminRole');
    }

    private saveBaseGridState(apiViewName: string, stateStr: string) {
        return $webApi.POST.Seeding.Grid_FactorySettings(apiViewName, stateStr);
    }

    private saveBUMGridState(apiViewName: string, stateStr: string) {
        return $webApi.POST.Seeding.Grid_BusinessUnitSettings(apiViewName, stateStr);
    }

    private async saveCustomView(apiViewName: string, stateStr: string, customName: string) {
        return $customViews.saveCustomView(apiViewName, stateStr, customName);
    }

    private saveBaseKpiSettings(settings: string) {
        return $webApi.POST.Seeding.KPI_FactorySettings(settings);
    }

    private saveBUMKpiSettings(settings: string) {
        return $webApi.POST.Seeding.KPI_BusinessUnitSettings(settings);
    }

    private saveUserKpiSettings(settings: string) {
        return $webApi.PUT.User.CockpitSettings('KpiSettings', 'Home', settings);
    }
    
    private extractRoutes(menuSettings: any): IRouteConfig[] {
        // workaround for a faulty user settings
        const routes = getCaseInsensitive(menuSettings, 'Routes', '[]');

        return JSON.parse(routes);
    }

    private clearPromise() {
        this.promiseDone = true;

        setTimeout(() => {
            this.settingsPromise = undefined;
        });
    }

    private async setSortColumnSettings(result: ICockpitSettingsResponse) {
        if (!result) { return; }

        try {
            const grid = getCaseInsensitive(result, 'SortColumnSettings');

            return sortModule.setSortColumnSettings(grid);
        } catch (error) {
            return;
        }
    }

    private async setGroupColumnSettings(result: ICockpitSettingsResponse) {
        if (!result) { return; }

        try {
            const grid = getCaseInsensitive(result, 'GroupColumnSettings');

            return groupModule.setGroupColumnSettings(grid);
        } catch (error) {
            return;
        }
    }

    saveCustomGridStateFor(scope: any, apiViewName: string, state: any, customName: string, saveForMe: boolean, saveForAll: boolean) {
        gridModule.setState({ apiViewName, dxState: state });
        const stateStr = typeof state === 'string' ? state : JSON.stringify(state);
        return $customViews.saveCustomScopeView(apiViewName, stateStr, customName || '', scope, saveForMe, saveForAll);
        
    }

    private saveUserGridState(apiViewName: string, stateStr: string) {
        return $webApi.POST.Seeding.Grid_UserSettings(apiViewName, stateStr);
    }
}

export const $settingsService = new SettingsService();
